import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Text } from "../../../../../../Inputs/react-hook-form";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import {
  SubmissionsBuilderFieldSettings,
  SubmissionsBuilderFormDataStructure,
} from "../../types";
import FieldsList from "../FieldsList";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";

const ObjectFieldTemplateSettings = ({
  fieldPath,
  readOnly,
}: SelectedSubmissionsBuilderField & SubmissionsBuilderFieldSettings) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      <div>
        <Label text={"Title"} htmlFor={`${fieldPath}.title`} required />
        <Text {...register(`${fieldPath}.title`)} disabled={readOnly} />
      </div>
      {!readOnly && <FieldsList />}
    </WidgetSettingsContainer>
  );
};

ObjectFieldTemplateSettings.displayName = "ObjectFieldTemplateSettings";

export default ObjectFieldTemplateSettings;
