import React from "react";
import { useTranslation } from "react-i18next";
import { Account } from "../../generated/graphql";
import {
  AccountLogo as StyledAccountLogo,
  AccountTitle,
} from "./__styles__/AccountLogo";

export const AccountLogo = ({
  account,
  ...props
}: { account: Pick<Account, "logoUrl" | "name"> } & Parameters<
  typeof StyledAccountLogo
>[0]) => {
  const { t } = useTranslation();
  const { logoUrl, name } = account;

  return (
    <StyledAccountLogo
      {...props}
      src={logoUrl}
      alt={t("alt-text-logo", { accountName: name })}
    />
  );
};

export const AccountLogoOrFallbackIcon = ({
  account,
  ...props
}: { account: Pick<Account, "name" | "logoUrl"> } & Parameters<
  typeof StyledAccountLogo
>[0]) => {
  const { name, logoUrl } = account;

  return (
    <>
      {logoUrl ? (
        <AccountLogo {...props} account={account} />
      ) : (
        <AccountTitle {...props}>{name[0]}</AccountTitle>
      )}
    </>
  );
};
